import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { TableViewSpec } from 'state-domains/domain/subscription';
import { buildConfigurationActivityDeleteTableUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendDeleteRequestWithXSRFToken } from '../../../utils';

export const deleteConfigurationActivityTable = (
    activityId: string,
    id: string,
): Observable<TableViewSpec> => {
    const obs = sendDeleteRequestWithXSRFToken(
        buildConfigurationActivityDeleteTableUrl(activityId, id),
    );
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<TableViewSpec>) => {
            const res = convertToCamel<TableViewSpec>(response);
            return observableOf(res);
        }),
    );
};
